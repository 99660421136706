<template>
  <div>
    <carousel
      :perPage="1"
      :paginationPadding="5"
      paginationPosition="bottom-overlay"
      class="carousel-slide"
      paginationActiveColor="#F47216"
      paginationColor="rgba(0, 0, 0, 0.774)"
      :value="value"
      @input="$emit('input', $event)"
      >
      <slide v-for="type in Object.keys(types)" :key="type" class="d-flex flex-column min-w-0">
        <h5>{{type}}</h5>
        <div class="gradient-container" :class="{ 'gradient-container-margin': Object.keys(types).length > 1 }">
          <p class="my-1 font-weight-bold gradient-text">{{types[type].min | round}} {{parsedUnit(types[type].min)}}</p>
          <div
            :style="{ 'background': `linear-gradient(to right, ${gradientColors.join(', ')})` }"
            :class="size === 'sm' ? 'gradient-bar-sm' : 'gradient-bar'">
          </div>
          <p class="my-1 font-weight-bold gradient-text">{{types[type].max | round}} {{parsedUnit(types[type].max)}}</p>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from '../carousel';
import { unitMagnitude } from './helpers';

export default {
  name: 'Gradient',
  components: {
    Carousel,
    Slide,
  },
  props: {
    value: {
      type: Number,
    },
    types: {
      type: Object,
      default: () => {},
    },
    gradientColors: {
      type: Array,
    },
    unit: {
      type: String,
    },
    size: {
      type: String,
      default: 'lg'
    }
  },
  methods: {
    parsedUnit(value) {
      return unitMagnitude(value, this.unit);
    }
  },
  filters: {
    round: (value) => {
      if (!value) return 0;
      if (value / 1000000000 >= 1) return (value / 1000000000).toFixed(1);
      if (value / 1000000 >= 1) return (value / 1000000).toFixed(1);
      if (value / 1000 >= 1) return (value / 1000).toFixed(1);
      return value.toFixed(1);
    }
  }
};
</script>

<style scoped>
  .carousel-slide{
    max-width: 340px;
    padding: 10px;
    border-radius: 0.25rem;
    background-color: rgba(245,245,245,0.8);
  }

  .gradient-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  .gradient-container-margin {
    margin-bottom: 25px;
  }

  .gradient-text {
    white-space: nowrap;
  }

  .gradient-bar {
    width: 200px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .gradient-bar-sm {
    width: 150px;
    height: 15px;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
  }
</style>
